export const LOGIN_ADMIN='LOGIN_ADMIN';
export const LOGOUT_ADMIN="LOGOUT_ADMIN";

export const ADD_ADMIN_INSTRUCTOR='ADD_ADMIN_INSTRUCTOR';
export const GET_ADMIN_INSTRUCTOR='GET_ADMIN_INSTRUCTOR';
export const APPROVE_INSTRUCTOR='APPROVE_INSTRUCTOR';
export const DECLINE_INSTRUCTOR='DECLINE_INSTRUCTOR';
export const DELETE_INSTRUCTOR='DELETE_INSTRUCTOR';
export const GET_DELETED_INSTRUCTOR='GET_DELETED_INSTRUCTOR';
export const RESTORE_INSTRUCTOR='RESTORE_INSTRUCTOR';
export const GET_ADMIN_INSTRUCTOR_BY_ID='GET_ADMIN_INSTRUCTOR_BY_ID';
export const UPDATE_INSTRUCTOR='UPDATE_INSTRUCTOR';
export const ADD_INSTRUCTOR_QUALIFICATION='ADD_INSTRUCTOR_QUALIFICATION';
export const ADD_INSTRUCTOR_EXPERIENCE='ADD_INSTRUCTOR_EXPERICENCE';
export const UPDATE_QUALIFICATION_STATUS='UPDATE_QUALIFICATION_STATUS';
export const UPDATE_INSTRUCTOR_QUALIFICATION='UPDATE_INSTRUCTOR_QUALIFICATION';
export const UPDATE_INSTRUCTOR_EXPERIENCE='UPDATE_INSTRUCTOR_EXPERIENCE';
export const DELETE_INSTRUCTOR_EXPERIENCE='DELETE_INSTRUCTOR_EXPERIENCE';
export const DELETE_INSTRUCTOR_QUALIFICATION='DELETE_INSTRUCTOR_QUALIFICATION';
export const DELETE_ADMIN_EXPERIENCE='DELETE_ADMIN_EXPERIENCE';
export const DELETE_ADMIN_QUALIFICATION='DELETE_ADMIN_QUALIFICATION';
export const GET_INSTRUCTOR_QUALIFICATION_BY_ID='GET_INSTRUCTOR_QUALIFICATION_BY_ID';
export const GET_INSTRUCTOR_EXPERIENCE_BY_ID='GET_INSTRUCTOR_EXPERIENCE_BY_ID';


export const ADD_ADMIN_STUDENT='ADD_ADMIN_STUDENT';
export const GET_ADMIN_STUDENT='GET_ADMIN_STUDENT';
export const DELETE_STUDENT='DELETE_STUDENT';
export const GET_DELETED_STUDENT='GET_DELETED_STUDENT';
export const RESTORE_STUDENT='RESTORE_STUDENT';
export const VERIFY_STUDENT='VERIFY_STUDENT';
export const GET_STUDENT_PROFILE='GET_STUDENT_PROFILE';
export const GET_DELETED_EXPERIENCE='GET_DELETED_EXPERIENCE';
export const GET_DELETED_QUALIFICATION='GET_DELETED_QUALIFICATION';
export const RESTORE_ADMIN_EXPERIENCE='RESTORE_ADMIN_EXPERIENCE';
export const RESTORE_ADMIN_QUALIFICATION='RESTORE_ADMIN_QUALIFICATION';

export const ADD_ADMIN_COURSE='ADD_ADMIN_COURSE';
export const GET_ADMIN_COURSE='GET_ADMIN_COURSE';
// export const GET_ADMIN_INSTRUCTOR_COURSE='GET_ADMIN_INSTRUCTOR_COURSE';
export const GET_COURSE_BY_ID='GET_COURSE_BY_ID';
export const ADD_ADMIN_COURSE_CONTENT='ADD_ADMIN_COURSE_CONTENT';

export const COURSE_STATUS='COURSE_STATUS';
export const FILE_STATUS='FILE_STATUS';
export const ADD_CATEGORY='ADD_CATEGORY';
export const GET_CATEGORY='GET_CATEGORY';
export const DELETE_CATEGORY='DELETE_CATEGORY';



export const ADD_INSTRUCTOR='ADD_INSTRUCTOR';
export const LOGIN_INSTRUCTOR='LOGIN_INSTRUCTOR';
export const LOGOUT_INSTRUCTOR='LOGOUT_INSTRUCTOR';
export const GET_INSTRUCTOR='GET_INSTRUCTOR';
export const VERIFY_OTP='VERIFY_OTP';
export const CONTENT_STATUS='CONTENT_STATUS';


export const ADD_INSTRUCTOR_COURSE='ADD_INSTRUCTOR_COURSE';
export const GET_INSTRUCTOR_COURSE='GET_INSTRUCTOR_COURSE';
export const GET_INSTRUCTOR_OTHER_COURSE='GET_INSTRUCTOR_OTHER_COURSE';
export const GET_INSTRUCTOR_CATEGORY='GET_INSTRUCTOR_CATEGORY';
export const GET_INSTRUCTOR_COURSE_BY_ID='GET_INSTRUCTOR_COURSE_BY_ID';
export const ADD_INSTRUCTOR_COURSE_CONTENT='ADD_INSTRUCTOR_COURSE_CONTENT';
export const INSTRUCTOR_COURSE_PUBLISH='INSTRUCTOR_COURSE_PUBLISH';
export const INSTRUCTOR_CONTENT_PUBLISH='INSTRUCTOR_CONTENT_PUBLISH';
export const INSTRUCTOR_FILE_PUBLISH='INSTRUCTOR_FILE_PUBLISH';
export const ADD_INSTRUCTOR_VIDEO='ADD_INSTRUCTOR_VIDEO';
export const ADD_INSTRUCTOR_FILES='ADD_INSTRUCTOR_FILE';
export const GET_INSTRUCTOR_CONTENT_BY_ID='GET_INSTRUCTOR_CONTENT_BY_ID';

export const COURSE_SUBMIT='COURSE_SUBMIT';
export const CONTENT_SUBMIT='CONTENT_SUBMIT';
export const CONTENT_FILE_SUBMIT='CONTENT_FILE_SUBMIT';
export const COURSE_PUBLISH='COURSE_PUBLISH';
export const CONTENT_PUBLISH='CONTENT_PUBLISH';
export const FILE_PUBLISH='FILE_PUBLISH';

export const GET_CONTENT_BY_ID='GET_CONTENT_BY_ID';
export const ADD_ADMIN_VIDEO='ADD_ADMIN_VIDEO';
export const ADD_ADMIN_FILES='ADD_ADMIN_FILES';

export const ADD_INSTRUCTOR_COUPON='ADD_INSTRUCTOR_COUPON';
export const GET_INSTRUCTOR_COUPON='GET_INSTRUCTOR_COUPON';
export const DELETE_INSTRUCTOR_COUPON='DELETE_INSTRUCTOR_COUPON';

export const ADD_COUPON='ADD_COUPON';
export const GET_COUPON='GET_COUPON';
export const DELETE_COUPON='DELETE_COUPON';
export const GET_DELETED_COUPON='GET_DELETED_COUPON';
export const RESTORE_COUPON='RESTORE_COUPON';

export const DELETE_INSTRUCTOR_CONTENT='DELETE_INSTRUCTOR_CONTENT';
export const DELETE_INSTRUCTOR_COURSE='DELETE_INSTRUCTOR_COURSE';
export const DELETE_INSTRUCTOR_FILE='DELETE_INSTRUCTOR_FILE';

export const DELETE_COURSE='DELETE_COURSE';
export const DELETE_CONTENT='DELETE_CONTENT';
export const DELETE_FILE='DELETE_FILE';

export const ADD_COUPON_TO_COURSE='ADD_COUPON_TO_COURSE';
export const COUPON_STATUS='COUPON_STATUS';

export const GET_INSTRUCTOR_TOTAL_COURSES='GET_INSTRUCTOR_TOTAL_COURSES';
export const GET_INSTRUCTOR_DRAFT_COURSES='GET_INSTRUCTOR_DRAFT_COURSES';
export const GET_INSTRUCTOR_ONGOING_COURSES='GET_INSTRUCTOR_ONGOING_COURSES';

export const GET_DELETED_COURSE='GET_DELETED_COURSE';
export const RESTORE_COURSE='RESTORE_COURSE';
export const RESTORE_CONTENT='RESTORE_CONTENT';
export const RESTORE_FILE='RESTORE_FILE';

export const COUPON_TO_COURSE='COUPON_TO_COURSE';

export const ADD_INOTIFICATION='ADD_INOTIFICATION';
export const ADD_ANOTIFICATION='ADD_ANOTIFICATION';

export const GET_ANOTIFICATION='GET_ANOTIFICATION';
export const GET_INOTIFICATION='GET_INOTIFICATION';
export const GET_NOTIFICATION='GET_NOTIFICATION';

export const NOTIFICATION_STATUS='NOTIFICATION_STATUS';

export const GET_IREVIEW='GET_IREVIEW';
export const DELETE_IREVIEW='DELETE_IREVIEW';
export const GET_IAVERAGE_RATING='GET_IAVERAGE_RATING';

export const GET_AREVIEW='GET_AREVIEW';
export const DELETE_AREVIEW='DELETE_AREVIEW';
export const GET_AAVERAGE_RATING="GET_AAVERAGE_RATING";

export const ASSIGN_COURSE='ASSIGN_COURSE';

export const GET_ICOURSE_AVERAGE_RATING=' GET_ICOURSE_AVERAGE_RATING';
export const GET_ICOURSE_REVIEW='GET_ICOURSE_REVIEW';

export const GET_ACOURSE_AVERAGE_RATING=' GET_ACOURSE_AVERAGE_RATING';
export const GET_ACOURSE_REVIEW='GET_ACOURSE_REVIEW';

export const REGISTER_STUDENT='REGISTER_STUDENT';
export const REGISTER_STUDENT_VERIFY_OTP='REGISTER_STUDENT_VERIFY_OTP';

export const GET_COURSE='GET_COURSE';
export const GET_SCOURSE='GET_SCOURSE';
export const ADD_PAYMENT='ADD_PAYMENT';

export const GET_INSTRUCTOR_STUDENT='GET_INSTRUCTOR_STUDENT';

export const GET_TOTAL_COURSE='GET_TOTAL_COURSE';
export const GET_DRAFT_COURSE='GET_DRAFT_COURSE';
export const GET_PENDING_COURSE='GET_PENDING_COURSE';
export const GET_TOTAL_INSTRUCTOR='GET_TOTAL_INSTRUCTOR';
export const GET_ATOTAL_STUDENT='GET_ATOTAL_STUDENT';
export const GET_PUBLISHED_COURSE='GET_PUBLISHED_COURSE';
export const GET_VERIFIED_COURSE='GET_VERIFIED_COURSE';

export const APPLY_COUPON_COURSE='APPLY_COUPON_COURSE';

export const GET_HEART='GET_HEART';

export const GET_COURSE_TYPE='GET_COURSE_TYPE';
export const ADD_COURSE_TYPE='ADD_COURSE_TYPE';
export const DELETE_COURSE_TYPE='DELETE_COURSE_TYPE';

export const GET_COURSE_DURATION='GET_COURSE_DURATION';
export const ADD_COURSE_DURATION='ADD_COURSE_DURATION';
export const DELETE_COURSE_DURATION='DELETE_COURSE_DURATION';

export const GET_UNIVERSITY='GET_UNIVERSITY';
export const ADD_UNIVERSITY='ADD_UNIVERSITY';
export const DELETE_UNIVERSITY='DELETE_UNIVERSITY';
export const GET_PARTICULAR_UNIVERSITY='GET_PARTICULAR_UNIVERSITY';
export const GET_PARTICULAR_INSTITUTE='GET_PARTICULAR_INSTITUTE';

export const GET_COURSE_DURATION_TYPE='GET_COURSE_DURATION_TYPE';
export const ADD_COURSE_DURATION_TYPE='ADD_COURSE_DURATION_TYPE';
export const DELETE_COURSE_DURATION_TYPE='DELETE_COURSE_DURATION_TYPE';

export const GET_YOGA_STUDIO='GET_YOGA_STUDIO';
export const YOGA_STUDIO_STATUS='YOGA_STUDIO_STATUS';

export const ADD_THERAPY_SPECIALISATION='ADD_THERAPY_SPECIALISATION';
export const GET_THERAPY_SPECIALISATION='GET_THERAPY_SPECIALISATION';
export const DELETE_THERAPY_SPECIALISATION='DELETE_THERAPY_SPECIALISATION';


export const ADD_HT_SPECIALISATION='ADD_HT_SPECIALISATION';
export const GET_HT_SPECIALISATION='GET_HT_SPECIALISATION';
export const DELETE_HT_SPECIALISATION='DELETE_HT_SPECIALISATION';

export const ADD_THERAPY_TYPE='ADD_THERAPY_TYPE';
export const GET_THERAPY_TYPE='GET_THERAPY_TYPE';
export const DELETE_THERAPY_TYPE='DELETE_THERAPY_TYPE';

export const ADD_BANNER='ADD_BANNER';
export const GET_BANNER='GET_BANNER';
export const DELETE_BANNER='DELETE_BANNER';

export const DELETE_HOME_TUTOR='DELETE_HOME_TUTOR';
export const GET_HOME_TUTOR='GET_HOME_TUTOR';
export const GET_HOME_TUTOR_BY_ID='GET_HOME_TUTOR_BY_ID';
export const GET_UPDATION_REQUEST='GET_UPDATION_REQUEST';
export const HOME_TUTOR_STATUS='HOME_TUTOR_STATUS';
export const DELETE_HOME_TUTOR_IMAGE='DELETE_HOME_TUTOR_IMAGE'
export const DELETE_HOME_TUTOR_LOCATION='DELETE_HOME_TUTOR_LOCATION';
export const DELETE_HOME_TUTOR_SLOT='DELETE_HOME_TUTOR_SLOT';
export const GET_HOME_TUTOR_DATE_SLOT='GET_HOME_TUTOR_DATE_SLOT';
export const HOME_TUTOR_UPDATION_STATUS='HOME_TUTOR_UPDATION_STATUS';